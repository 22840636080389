import { RefundBankInfo } from "../../components/modal/history/SelctBankModal";
import { dateFormater, diffDays } from "../utils/DateUtils";

export type NullOrUndefined = null | undefined;

export const giftcardPrefix: string = process.env.NEXT_PUBLIC_GIFTCARD_PREFIX ?? "";

export const MailSuffixType = [
  "직접입력",
  "naver.com",
  "gmail.com",
  "daum.net",
  "dreamwiz.com",
  "kakao.com",
];

export const enum CashLockState {
  UNLOCK_90 = "0",
  LOCKED = "1",
  UNLOCK_HOURS = "2",
}

export const enum UserAuthType {
  AUTH_USER_SELF = "1",
  AUTH_SIGN_UP_PARENT = "2",
  AUTH_CHANGE_USER_INFO = "4",
  AUTH_SIGN_OUT_USER = "5",
  AUTH_FIND_ID = "6",
  AUTH_FIND_PASSWORD = "7",
  AUTH_SET_CASH_LOCK = "8",
  AUTH_SELECT_ACCOUNT = "9",
  AUTH_RECOVERY = "11",
  AUTH_EXCHANGE = "14",
  AUTH_CHARGE = "15",
  AUTH_CHECK_PIN = "16",
}

export const enum ModalExpire {
  PASSWORD_MODAL_EXPIRE = "PASSWORD_MODAL_EXPIRE",
  THIRD_MODAL_EXPIRE = "THIRD_MODAL_EXPIRE",
  PUSH_MODAL_EXPIRE = "PUSH_MODAL_EXPIRE",
  MAIN_POPUP_EXPIRE = "MAIN_POPUP_EXPIRE",
  MAIN_TOP_BANNER_EXPIRE = "MAIN_TOP_BANNER_EXPIRE",
  MAIN_CASH_LIMIT_EXPIRE = "MAIN_CASH_LIMIT_EXPIRE",
  ACCOUNT_CERT_LIMIT_EXPIRE = "ACCOUNT_CERT_LIMIT_EXPIRE",
}

export const TermsType = {
  SERVICE_TERMS: {
    name: "서비스 이용약관 동의",
    stplatFlag: "02",
    stplatSeq: "104",
    require: true,
  },
  CASH_TERMS: {
    name: "캐시 이용약관 동의",
    stplatFlag: "03",
    stplatSeq: "110",
    require: true,
  },
  PRIVATE_TERMS: {
    name: "개인정보 처리방침 동의",
    stplatFlag: "13",
    stplatSeq: "114",
    require: true,
  },
  PRIVATE_3RD_TERMS: {
    name: "개인정보 제3자 제공동의",
    stplatFlag: "11",
    stplatSeq: "111",
    require: false,
  },
  MARKETING_TERMS: {
    name: "마케팅 활용, 혜택정보 수신동의",
    stplatFlag: "14",
    stplatSeq: "105",
    require: false,
  },
} as const;

export const enum CellPhoneAuthType {
  AUTH_ID_FINDE = "idFind",
  AUTH_CHARGE_GIFTCARD = "2",
  UNLOCK_ONE_HOUR = "7",
  FIND_ID = "8",
  GIFT_CARD_MULTIPLE_CHARGE = "9",
}

export const enum CellPhoneAuthExpire {
  MASS_CHARGE_EXPIRE = "MASS_CHARGE_EXPIRE",
}

export const refundBankList: RefundBankInfo[] = [
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-034@3x.png",
    refundBankCd: "034",
    refundBankNm: "광주은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-224@3x.png",
    refundBankCd: "039",
    refundBankNm: "경남은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-218@3x.png",
    refundBankCd: "004",
    refundBankNm: "국민은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-225@3x.png",
    refundBankCd: "003",
    refundBankNm: "기업은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-247@3x.png",
    refundBankCd: "011",
    refundBankNm: "농협",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-209@3x.png",
    refundBankCd: "209",
    refundBankNm: "유안타증권",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-031@3x.png",
    refundBankCd: "031",
    refundBankNm: "대구은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-267@3x.png",
    refundBankCd: "267",
    refundBankNm: "대신증권",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-238@3x.png",
    refundBankCd: "230",
    refundBankNm: "미래에셋대우",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-224@3x.png",
    refundBankCd: "032",
    refundBankNm: "부산은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-002@3x.png",
    refundBankCd: "002",
    refundBankNm: "산업은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-240@3x.png",
    refundBankCd: "240",
    refundBankNm: "삼성증권",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-007@3x.png",
    refundBankCd: "007",
    refundBankNm: "수협은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-045@3x.png",
    refundBankCd: "045",
    refundBankNm: "새마을금고",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-035@3x.png",
    refundBankCd: "088",
    refundBankNm: "신한은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-023@3x.png",
    refundBankCd: "23",
    refundBankNm: "SC제일은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-048@3x.png",
    refundBankCd: "048",
    refundBankNm: "신협중앙회",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-020@3x.png",
    refundBankCd: "020",
    refundBankNm: "우리은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-011@3x.png",
    refundBankCd: "247",
    refundBankNm: "NH투자증권",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-071@3x.png",
    refundBankCd: "071",
    refundBankNm: "우체국",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-081@3x.png",
    refundBankCd: "005",
    refundBankNm: "외환은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-034@3x.png",
    refundBankCd: "037",
    refundBankNm: "전북은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-088@3x.png",
    refundBankCd: "035",
    refundBankNm: "제주은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-081@3x.png",
    refundBankCd: "081",
    refundBankNm: "하나은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-027@3x.png",
    refundBankCd: "027",
    refundBankNm: "한국씨티은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-269@3x.png",
    refundBankCd: "269",
    refundBankNm: "한화투자증권",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-090@3x.png",
    refundBankCd: "090",
    refundBankNm: "카카오뱅크",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-089@3x.png",
    refundBankCd: "089",
    refundBankNm: "케이뱅크",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-103@3x.png",
    refundBankCd: "050",
    refundBankNm: "SBI저축은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-060@3x.png",
    refundBankCd: "060",
    refundBankNm: "뱅크오브아메리카",
  },
];

//이니시스 결제 환불 은행코드
export const refundBankList_iniPay: RefundBankInfo[] = [
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-225@3x.png",
    refundBankCd: "03",
    refundBankNm: "기업은행",
  },
  // {
  //   refundBankImg: "/assets/images/bank/ico-bank-bank-081@3x.png",
  //   refundBankCd: "05",
  //   refundBankNm: "외환은행",
  // },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-218@3x.png",
    refundBankCd: "06",
    refundBankNm: "국민은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-247@3x.png",
    refundBankCd: "11",
    refundBankNm: "농협은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-020@3x.png",
    refundBankCd: "20",
    refundBankNm: "우리은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-035@3x.png",
    refundBankCd: "88",
    refundBankNm: "신한은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-023@3x.png",
    refundBankCd: "23",
    refundBankNm: "SC제일은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-027@3x.png",
    refundBankCd: "27",
    refundBankNm: "씨티은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-031@3x.png",
    refundBankCd: "31",
    refundBankNm: "대구은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-224@3x.png",
    refundBankCd: "32",
    refundBankNm: "부산은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-034@3x.png",
    refundBankCd: "34",
    refundBankNm: "광주은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-034@3x.png",
    refundBankCd: "37",
    refundBankNm: "전북은행",
  },

  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-224@3x.png",
    refundBankCd: "39",
    refundBankNm: "경남은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-071@3x.png",
    refundBankCd: "71",
    refundBankNm: "우체국은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-081@3x.png",
    refundBankCd: "81",
    refundBankNm: "하나은행",
  },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-048@3x.png",
    refundBankCd: "48",
    refundBankNm: "신협은행",
  },
  // {
  //   refundBankImg: "/assets/images/bank/ico-bank-bank-045@3x.png",
  //   refundBankCd: "45",
  //   refundBankNm: "새마을금고",
  // },
  // {
  //   refundBankImg: "/assets/images/bank/ico-bank-bank-088@3x.png",
  //   refundBankCd: "35",
  //   refundBankNm: "제주은행",
  // },
  {
    refundBankImg: "/assets/images/bank/ico-bank-bank-007@3x.png",
    refundBankCd: "07",
    refundBankNm: "수협은행",
  },
  // {
  //   refundBankImg: "/assets/images/bank/ico-bank-bank-002@3x.png",
  //   refundBankCd: "02",
  //   refundBankNm: "산업은행",
  // },
  // {
  //   refundBankImg: "/assets/images/bank/ico-bank-bank-090@3x.png",
  //   refundBankCd: "90",
  //   refundBankNm: "카카오뱅크",
  // },
];

export const isItEventRefundPeriod =
  dateFormater("yyyy-MM-dd HH:mm") >= dateFormater("yyyy-MM-dd HH:mm", "2023-09-22 00:00") &&
  dateFormater("yyyy-MM-dd HH:mm") <= dateFormater("yyyy-MM-dd HH:mm", "2023-09-26 23:59");

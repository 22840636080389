import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  CellPhoneAuthExpire,
  ModalExpire,
} from "../../common/constants/Constants";
import {
  getTokenExpireDate,
  isNullorEmpty,
  isTokenExpired,
  isUserVerified,
} from "../../common/utils/CommonUtils";
import { decrypt, encrypt } from "../../common/utils/CryptoUtils";
import { ModalTypes } from "../../components/common/modal/ModalTable";
import { AlertProps } from "../../components/modal/CustomAlertModal";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useModal } from "../../hooks/useModal";
import { useRefreshTokenMutation } from "../../query/auth/useTokenMutation";
import {
  useUserCashInfoMutation,
  useUserInfoMutation,
} from "../../query/user/useUserMutation";
import { loadingAtom } from "../../recoil/loading/loadingAtom";
import {
  selectUserInfo,
  updateUserCash,
  userInfoAtom,
} from "../../recoil/user/userAtom";
import { AuthContext } from "./AuthContext";

type AuthProps = {
  children: React.ReactNode;
  requireAuth?: boolean;
  redirectTo?: string;
};

export const AuthProvider = ({
  children,
  requireAuth,
  redirectTo,
}: AuthProps) => {
  const router = useRouter();
  const alert = useModal<AlertProps>(ModalTypes.ALERT);
  const [_, setShowCashLock] = useLocalStorage<string>("showCashLock", "false");

  const setLoading = useSetRecoilState(loadingAtom);
  const [updateCash, setUpdateCash] = useRecoilState(updateUserCash);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const userInfo = useRecoilValue(selectUserInfo);

  const tokenMutation = useRefreshTokenMutation();
  const userMutation = useUserInfoMutation();
  const userCashMutation = useUserCashInfoMutation();

  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = !!userInfo || !isNullorEmpty(Cookies.get("token"));

  const isFallback = (
    token?: string,
    isDynamicAuth: boolean = false
  ): boolean => {
    const tokeCheck = token ? token : decrypt(Cookies.get("token"));
    if (!tokeCheck) {
      logout();

      if (requireAuth || isDynamicAuth) {
        alert.openModal({
          contents:
            "로그인 후 이용 가능합니다.\n로그인 페이지로 이동하시겠습니까?",
          confirmText: "확인",
          cancelText: "취소",
          onConfirm: () => {
            router.replace({
              pathname: redirectTo || "/auth/login",
              query: {
                redirect: encodeURI(router.asPath),
              },
            });
          },
          onCancel: () => requireAuth && router.back(),
        });
      }

      return true;
    }

    return false;
  };

  const authenticate = async (token: string) => {
    if (isTokenExpired(token) === false) return;

    setIsLoading(true);
    try {
      const res = await tokenMutation.mutateAsync();

      setUserInfo(userInfo);

      Cookies.set("token", encrypt(res.accessToken), {
        expires: getTokenExpireDate(res.accessToken),
        path: "/",
      });
    } catch (error) {
      console.log({ error });

      logout(redirectTo || "/main");
    }

    setIsLoading(false);
  };

  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      const res = await userMutation.mutateAsync();

      setUserInfo({
        ...res,
        ["cashBal"]: decrypt(res.cashBal),
        ["hpNo"]: decrypt(res.hpNo),
        ["membMail"]: decrypt(res.membMail),
        ["membNm"]: decrypt(res.membNm),
      });
    } catch (error) {
      console.log({ error });
      logout(redirectTo || "/main");
    }

    setIsLoading(false);
  };

  const getUserCashInfo = async () => {
    try {
      const res = await userCashMutation.mutateAsync();
      setUserInfo((prev) => {
        return !prev
          ? prev
          : {
              ...prev,
              ["cashBal"]: res.cashBal,
              ["actStat"]: res.actStat,
            };
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const logout = (redirectTo?: string) => {
    Cookies.remove("token");
    Cookies.remove("crtfcId");
    Cookies.remove("procSeq");
    Cookies.remove(CellPhoneAuthExpire.MASS_CHARGE_EXPIRE);
    Cookies.remove(ModalExpire.MAIN_CASH_LIMIT_EXPIRE);
    Cookies.remove(ModalExpire.ACCOUNT_CERT_LIMIT_EXPIRE);
    setUserInfo(null);
    setLoading(false);
    setShowCashLock("false");

    redirectTo && router.replace(redirectTo);
  };

  useEffect(() => {
    const token = decrypt(Cookies.get("token") ?? "");

    if (!token) return;

    getUserInfo();
  }, []);

  useEffect(() => {
    const token = decrypt(Cookies.get("token") ?? "");

    if (isLoading || isFallback(token)) return;
    if (isAuthenticated && isUserVerified(token, userInfo?.membId) === false) {
      console.log("changed login user");
      getUserInfo();
    }

    authenticate(token);
  }, [router.asPath]);

  useEffect(() => {
    const token = decrypt(Cookies.get("token") ?? "");

    if (!token || updateCash === false) return;

    getUserCashInfo();
    setUpdateCash(false);
  }, [updateCash]);

  if (isLoading) return null;

  if (requireAuth && !userInfo) {
    const child = children as ReactNode[];

    return (
      <AuthContext.Provider
        value={{
          userInfo: userInfo,
          isAuthenticated: isAuthenticated,
          logout,
          isFallback,
          getUserInfo,
        }}
      >
        {child[0] ?? null}
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        userInfo: userInfo,
        isAuthenticated: isAuthenticated,
        logout,
        isFallback,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { TokenVO } from "./../models/TokenVO";
import { API_OK } from "../RemoteConfig";
import AuthService from "../services/AuthService";
import { makeError } from "../Request";
import { DanalAuthReadyVO } from "../models/DanalAuthReadyVO";
import { PhoneAuthVO } from "../models/PhoneAuthVO";
import { NullOrUndefined } from "../../common/constants/Constants";
import { KakaoAuthVO } from "../models/KakaoAuthVO";
import { EcouponTokenVO } from "../models/EcouponTokenVO";
import { VerifyAccountResponse } from "../../interface/common/BankVerify.interface";

class AuthRepository {
  requestPhoneAuth(params: any): Promise<PhoneAuthVO | NullOrUndefined> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.requestPhoneAuth(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  checkPhoneAuth(params: any): Promise<PhoneAuthVO | NullOrUndefined> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.checkPhoneAuth(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  requestPhoneAuthNoToken(params: any): Promise<PhoneAuthVO | NullOrUndefined> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.requestPhoneAuthNoToken(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  checkPhoneAuthNoToken(params: any): Promise<PhoneAuthVO | NullOrUndefined> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.checkPhoneAuthNoToken(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  issueAccessToken(): Promise<TokenVO> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.IssueAccessToken();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  getEcouponToken(): Promise<EcouponTokenVO> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.getEcouponToken();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  danalAuthReady(params: any): Promise<DanalAuthReadyVO> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.danalAuthReady(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  kakaoAuthReady(params: any): Promise<KakaoAuthVO> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.kakaoAuthReady(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  kakaoAuthStatus(params: any): Promise<KakaoAuthVO> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.kakaoAuthStatus(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res, res.ResultData));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  getVerifyAccountCoocon(params: any): Promise<VerifyAccountResponse> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.getVerifyAccountCoocon(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }
  getVerifyAccountCodeCoocon(params: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await AuthService.getVerifyAccountCodeCoocon(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }
}

export default new AuthRepository();

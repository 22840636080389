import { VerifyAccountResponse } from '../../interface/common/BankVerify.interface';
import { ResponseData } from '../models/base/ResponseData';
import { DanalAuthReadyVO } from '../models/DanalAuthReadyVO';
import { EcouponTokenVO } from '../models/EcouponTokenVO';
import { KakaoAuthVO } from '../models/KakaoAuthVO';
import { PhoneAuthVO } from '../models/PhoneAuthVO';
import { TokenVO } from '../models/TokenVO';
import { request, requestFetch } from "../Request";

const AuthService = {
  requestPhoneAuth: (params: any) => request<ResponseData<PhoneAuthVO>>({ url: "/api/Common/SendCertNo", method: "post", data: params }, true),
  checkPhoneAuth: (params: any) => request<ResponseData<PhoneAuthVO>>({ url: "/api/Common/VerifyCertNo", method: "post", data: params }, true),
  requestPhoneAuthNoToken: (params: any) => request<ResponseData<PhoneAuthVO>>({ url: "/api/Common/SendCertNoByNotAuth", method: "post", data: params }),
  checkPhoneAuthNoToken: (params: any) => request<ResponseData<PhoneAuthVO>>({ url: "/api/Common/VerifyCertNoByNotAuth", method: "post", data: params }),
  danalAuthReady: (params: any) => request<ResponseData<DanalAuthReadyVO>>({ url: "/api/WebAuth/DanalAuthReady", method: "post", data: params }),
  IssueAccessToken: () => requestFetch<ResponseData<TokenVO>>("/api/Auth/TokenIssue", {}),
  kakaoAuthReady: (params: any) => requestFetch<ResponseData<KakaoAuthVO>>("/api/Auth/KakaoAuthReady", params),
  kakaoAuthStatus: (params: any) => requestFetch<ResponseData<KakaoAuthVO>>("/api/Auth/KakaoAuthStatus", params),
  getEcouponToken: () => request<ResponseData<EcouponTokenVO>>({ url: "/api/CoopGift/GetUserInfo", method: "post" }, true),
  getVerifyAccountCoocon: (params: any) => requestFetch<ResponseData<VerifyAccountResponse>>("/api/Auth/CooconBankAcntSend", params),
  getVerifyAccountCodeCoocon: (params: any) => requestFetch<ResponseData<any>>("/api/Auth/CooconBankAcntCdChk", params),
};

export default AuthService;

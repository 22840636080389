import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { modalIdsAtom, selectModal } from "../../../recoil/modal/modalAtom";
import { ModalTable } from "./ModalTable";

export type BaseProps = {
  title?: string;
  contents: string;
};

export const useModalReset = () => {
  return useResetRecoilState(modalIdsAtom);
};

const ModalManager = () => {
  const modals = useRecoilValue(selectModal);
  const resetAtom = useResetRecoilState(modalIdsAtom);
  
  const goHistoryBack = () => {
    resetAtom();
  };
  
  useEffect(() => {
    if (modals.every((data) => data.isOpen == false)) {
      resetAtom();
    }
  }, [modals])

  useEffect(() => {
    window.addEventListener("popstate", goHistoryBack);

    return () => {
      window.removeEventListener("popstate", goHistoryBack);
    };
  }, []);

  return (
    <div>
      {modals.map((modal, idx) => {
        // index 에 지정된 component 정보 가져오기
        const preConfig = ModalTable.find((mi) => mi.type === modal.type);

        if (!preConfig || !preConfig.component) {
          console.error("not found modal component");
          return null;
        }

        const ModalComponent = modal.component;

        const { props } = modal;

        return (
          <div key={idx}>
            <ModalComponent {...props} />
          </div>
        );
      })}
    </div>
  );
};

export default ModalManager;
